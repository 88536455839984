<template>
  <div :class="{ 'c-dark-theme': false, animated: false, fadeIn: true }">
      <!-- 탱크정보 관리 --------------------------------------------------------------------------------------------->

      <BCard header-tag="header" footer-tag="footer">

        <div slot="header">
          <BIconServer/> <strong> 탱크정보관리 </strong>
          <div class="card-header-actions">
            <small class="text-muted">탱크정보를 관리합니다.</small>
          </div>
        </div>

        <BRow class="mb-2">
          <BCol>

            <BInputGroup size="sm">
              <BButton size="sm" variant="primary" class="mr-3" @click="createTank">
                <BIconNodePlusFill/> 탱크정보 생성
              </BButton>

              <BInputGroupAppend>
                <BFormSelect size="sm" v-model="searchField" :options="searchOpts" style="background-color:#2f303a"/>
                <BFormInput size="sm" v-model="searchWord" type="text" @keyup.enter="getTankList"/>
              </BInputGroupAppend>

              <BButton size="sm" variant="info" @click="getTankList">
                <BIconSearch/> 검 색
              </BButton>
            </BInputGroup>

          </BCol>
          <BCol sm="2" class="text-right">
            <BInputGroup size="sm">
              <BFormSelect size="sm" v-model="$store.state.numOfTankItem" :options="[10,15,20,30,50,100]" style="background-color:#2f303a"/>
              <BInputGroupAppend>
                <BButtonGroup>
                  <BButton size="sm" variant="primary" @click="getTankList"><BIconArrowRepeat/></BButton>
                  <BButton v-if=false size="sm" variant="info" @click="()=>{this.downloadModalShow = true}"><BIconFileSpreadsheetFill/></BButton>
                </BButtonGroup>
              </BInputGroupAppend>
            </BInputGroup>
          </BCol>
        </BRow>

        <BRow>
          <BCol>
            <vue-excel-editor
              v-model="rows"
              ref="excelGrid"
              width="100%"
              class="mb-1"
              :page="$store.state.numOfTankItem"
              :readonly-style="{backgroundColor:'#4F5040'}"
              @update="updateCell"
              @select="selectRecord"
              :localized-label="excelEditorLabel"
              :key="tableKey"
              filter-row>
              <vue-excel-column field="_id"                     type="string"   key-field invisible/>
              <vue-excel-column field="tid"                     type="string"   width="80px" label='탱크ID' sticky readonly/>

              <vue-excel-column field="name"                    type="string"   width="130px" label='탱크명' sticky/>
              <vue-excel-column field="tankCode"                type="string"   width="100px" label='탱크코드' :to-value="v=>{return v.toUpperCase()}" sticky/>
              <vue-excel-column field="machine"                 type="string"   width="210px" label='설치장비' :to-text="v=>{return v?`[${v.mid}] ${v.name}`:'미설치'}" sticky readonly/>
              <vue-excel-column field="arCode"                  type="map"      width="120px" label='관제지역' :options="arCodeMap" sticky/>
              <vue-excel-column field="tankType"                type="map"      width="100px" label='종류'  :options="maps.TANK_TYPE"/>
              <vue-excel-column field="tankUse"                 type="map"      width="100px" label='용도'  :options="maps.TANK_USE"/>
              <vue-excel-column field="tankShape"               type="map"      width="100px" label='탱크모형'  :options="maps.TANK_SHAPE"/>
              <vue-excel-column field="tableCount"              type="number"   width="80px" label='테이블수' readonly/>
              <vue-excel-column field="order"                   type="number"   width="80px" label='표시순서'/>
              <!--                <vue-excel-column field="color"                   type="string"   width="90px" label='칼라'/>-->
              <vue-excel-column field="oilCode"                 type="map"      width="100px" label='유종' :options="maps.OIL_CODE"/>
              <vue-excel-column field="dfCode"                  type="string"   width="100px" label='D/F코드'/>
              <vue-excel-column field="tankHeight"              type="number"   width="100px" label='높이'/>
              <vue-excel-column field="tankVolume"              type="number"   width="100px" label='용량'/>
              <vue-excel-column field="realVolRate"             type="number"   width="100px" label='실제용량'/>
              <vue-excel-column field="volRevision"             type="number"   width="100px" label='환산보정'/>
              <vue-excel-column field="sensorType"              type="map"      width="100px" label='센싱방식'  :options="maps.SENSOR_TYPE"/>

              <vue-excel-column field="nozType"                 type="map"      width="100px" label='노즐타입'  :options="maps.NOZ_TYPE"/>
              <vue-excel-column field="nozSize"                 type="map"      width="90px" label='노즐크기'  :options="maps.NOZ_SIZE"/>
<!--              <vue-excel-column field="cleanDate"               type="date"     width="110px" label='세척일' :to-text="toLocalTime"/>-->
<!--              <vue-excel-column field="maDate"                  type="date"     width="110px" label='보수일' :to-text="toLocalTime"/>-->
              <vue-excel-column field="location"                type="string"  width="190px" label='위치(X,Y)' :to-text="v=>{return v.coordinates?.toString()}"/>
<!--              <vue-excel-column field="location.x"              type="string"  width="60px" label='경도'/>-->
              <vue-excel-column field="display"                 type="map"  width="70px" label='모니터링' :options="{true:'Y', false: 'N'}"/>

              <vue-excel-column field="eventEnabled"            type="map"   width="60px" label='임계치' :options="{true:'Y', false: 'N'}" readonly/>
              <vue-excel-column field="alarm"                   type="string"  width="60px" label='알람' :to-text="v=>{return v.enabled?'Y':'N'}" readonly/>

              <vue-excel-column field="senderCode"              type="string"   width="120px" label='I/F코드' />

              <vue-excel-column field="comment"                 type="string"   width="150px" label='비고' />

              <vue-excel-column field="updatedAt"               type="date"     width="110px" label='수정일' :to-text="toLocalTime" readonly/>
              <vue-excel-column field="updId"                   type="string"   width="100px" label='수정자' readonly/>
              <vue-excel-column field="createdAt"               type="date"     width="110px" label='등록일' :to-text="toLocalTime" readonly/>
              <vue-excel-column field="regId"                   type="string"   width="100px" label='등록자' readonly/>
            </vue-excel-editor>
          </BCol>
        </BRow>


        <BRow>
          <BCol>
            <BButtonGroup>
              <BButton variant="info"  @click="showDetail" :disabled="selectedTank===null">
                <BIconInfoSquareFill/> 상세 보기
              </BButton>
              <BButton variant="danger" class="ml-2" @click="deleteRecord" :disabled="selectedTank===null">
                <BIconTrashFill/> 선택 정보 삭제
              </BButton>
            </BButtonGroup>
          </BCol>
          <BCol>
            <BProgress height="2rem" :value="progCount" :max="totalCount" variant="warning" show-value/>
          </BCol>
        </BRow>
      </BCard>



      <BModal id="modal-detail"
              ref="modalDetail"
              size="xl"
              title="탱크 상세정보"
              hide-header
              @ok="getTankList"
              scrollable no-close-on-backdrop>
        <BRow>
          <BCol xl="6">
            <BCard v-if="selectedTank!==null"  class="p-1 min-vh-100"  no-body>
              <BButton block variant="dark" class="mb-2">탱크 정보</BButton>
              <tank-form :tank-id="selectedTank._id"></tank-form>
            </BCard>
          </BCol>
          <BCol xl="6">
            <BCard v-if="selectedTank!==null" class="p-1 min-vh-100" no-body>
              <BButton block variant="dark" class="mb-2">장비 정보</BButton>
              <machine-form :machine-id.sync="selectedMachineId" :tank-id="selectedTank._id"/>
            </BCard>
          </BCol>
        </BRow>
      </BModal>

      <CModal ref="download-modal"
              color="warning"
              title="다운로드 사유 입력"
              :show.sync="downloadModalShow">
        <BFormInput v-model="downloadReason" debounce="6000"></BFormInput>
        <b-button class="mt-3" variant="outline-danger" block @click="exportExcel"><BIconDownload/> 다운로드</b-button>
      </CModal>

  </div>
</template>

<style src="spinkit/spinkit.min.css"></style>

<script>


//-------------------------------------------------------------------------------------------------
import '@/common/HelperMixin';
import {
  apiCall,
  cloneVar,
  accessLogging, getRandomHex, setTanks,
} from '@/common/utils';
import qs from 'querystring';
import moment from "moment";
import {ExcelEditorLabel} from '@/common/constants'
import TankForm from '../components/TankForm'
import MachineForm from '../components/MachineForm'
import {TankSchema, TankColumnMap} from '@/common/schema.js'

// const recvTypeMap = { manual: "매뉴얼", api: "API", socket: "소켓" };


//----------------------------------------------------------------------------------------------------
export default {
  name: "Tank",
  components: {
    TankForm, MachineForm
  },
  data() {
    return {
      maps: this.$store.state.codeMaps,
      selectedTank: null,
      selectedTableCount: 0,
      selectedMachineId: null,
      tank: null,
      machine: null,
      downloadModalShow: false,
      machineMap: {},
      oilCodeMap: {},
      arCodeMap: this.$store.state.area['map'],
      arCodeOpts: this.$store.state.area['opts'],
      downloadReason: '',
      searchOpts: [
        {value: 'tid', text: '탱크아이디'},
        {value: 'name', text: '탱크명'},
        {value: 'arCode', text: '그룹코드'},
      ],
      searchField: 'tid',
      searchWord: '',
      progCount: 0,
      totalCount: 0,
      rows: [],

      paramMap: {},
      numOfRecord: 10,

      modalTitle: '',
      tankFormShow: false,
      tableKey: 0

    }

  },
  async created() {
    try {
      await this.getTankList();
    } catch (err) {
      console.log(err);
    }
  },

  computed: {
    excelEditorLabel() {
      return ExcelEditorLabel
    }
  },

  mounted() {
    console.log("--- Tank mounted---------------------");
    // below is not work!
  },

  methods: {

    async createTank() {
      let newTank;
      try {

        if (!await this.confirmModal(`신규 탱크정보를 등록합니다. 진행 하시겠습니까?`, '탱크정보 등록')) {
          await this.alertModal('탱크정보 등록 취소', '탱크정보');
          return;
        } else {
          newTank = cloneVar( TankSchema );
        }

        if (this.selectedTank !== null) {
          if (await this.confirmModal(`탱크아이디 '${this.selectedTank.tid}' 정보를 복제하여 등록합니다. 진행하시겠습니까?`, '탱크정보 복제')) {
            newTank = cloneVar(this.selectedTank); // 복제등록 _id 제거, 제거안하면 duplication error
            delete newTank._id;

          }
        }

        newTank.tid = getRandomHex(4);
        newTank.mid = '';
        newTank.machine = null;

        const r = await apiCall('post', `/api/tank`, newTank);

        if (r.code === 200) {
          await this.alertModal('탱크정보가 등록되었습니다', '탱크정보 등록', 'success');
          await this.getTankList();
        } else {
          await this.alertModal('탱크정보 등록 실패: ' + r.message, '탱크정보 등록 실패', 'warning');
        }

      } catch (err) {
        console.log(err);
        await this.alertModal('탱크정보 등록 실패:' + err.message, '탱크정보 등록 오류', 'danger');
      }

    },


    async getTankList() {
      try {
        let qry = '';
        this.paramMap = {};
        if (this.searchWord) this.paramMap[this.searchField] = this.searchWord;
        qry = qs.stringify(this.paramMap);
        console.log("query-string ---------- ", qry);


        this.rows = [];
        const r = await apiCall('get', `/api/tank?${qry}`);
        if (r.code === 200) {
          this.rows = r.result;
          await this.toastInfo(`${this.rows.length}건 조회됨`, 'info');
          // console.log( 'task-data-query-result--->', r.result  );
        }
        // this.$refs['excelGrid'].clearFilter();
        this.selectedTank = null;

      } catch (err) {
        // console.log( 'getTankList----------------->', err );
        this.toastError(err);
      }
    },

    async exportExcel() {
      try {
        if (!this.downloadReason || this.downloadReason.length < 5) {
          return await this.alertWarn("다운로드 사유는 5자 이상 입력해야 합니다.", '사유 없음');
        }

        const numRecs = this.$refs['excelGrid'].getSelectedRecords().length;

        if (!numRecs) {
          this.$refs['download-modal'].hide();
          return await this.alertWarn("선택한 레코드만 파일로 생성됩니다.", '선택 레코드 없음');
        }
        const {currentRoute} = this.$router;
        const {fields} = this.$refs["excelGrid"];
        const format = 'xlsx';
        const exportSelectedOnly = true;

        const filename = currentRoute.path.split('/').pop() + '-' + moment().format('YYYY-MM-DD');
        const labels = fields.map(i => {
          return i.label
        })
        const rs = await accessLogging(
          'download',
          currentRoute,
          labels,
          this.downloadReason,
          `레코드수: ${numRecs}, 파일명: ${filename}.${format}`
        );
        if (rs === true) {
          this.$refs["excelGrid"].exportTable(format, exportSelectedOnly, filename);
          this.downloadReason = '';
          return true;
        } else {
          await this.alertDanger("ERROR: " + rs.message);
          return false;
        }

      } catch (err) {
        console.log('exportExcel error', err);
      } finally {
        this.$refs['download-modal'].hide();
      }
    },

    async selectRecord(idx, evt) {
      if (!evt) {
        // this.tankFormShow = false;
        this.selectedTank = null;
        this.selectedMachineId = null;
        return;
      }

      this.selectedTank = this.$refs['excelGrid'].getSelectedRecords()[0];
      this.selectedMachineId = (this.selectedTank.machine) ? this.selectedTank.machine._id : null;

      console.log('select idx, tank --->', idx, this.selectedTank);

    },
    async showDetail() {
      try {
        // console.log("arr -----------------> ", arr);
        if (!this.selectedTank) {
          await this.toastInfo(`레코드를 선택 하세요`, 'warning');
          return;
        }
        this.$refs['modalDetail'].show();

      } catch (err) {
        console.log("showDetail error:", err);
      }
    },


    validateCell(val, oldVal, rec, field) {
      console.log("----------------validateCell-------------------");
      console.log("validateCell---value ---> ", val);
      console.log("validateCell---oldVal ---> ", oldVal);
      console.log("validateCell---rec ---> ", rec);
      console.log("validateCell---field ---> ", field);
      // console.log( "validateCell---",this.$refs['excelGrid'] );
      // console.log( "validateCell---",this.$refs['excelGrid'].selectRecord(0) );
    },


    async updateCell(rec) {
      // console.log( "updateCell --- rec --->", rec );
      let rc = rec[0];
      if (!rc.keys[0]) {
        alert("KEY_NOT_FOUND");
        return;
      }

      console.log("updateCell--------- record.$id--->", rc.$id);
      console.log("updateCell--------- record.keys[0]--->", rc.keys[0]);
      console.log( "updateCell--------- rec[0]|rc --->",rc );
      const tIndex = this.$refs['excelGrid'].rowIndex[rc.$id];
      // console.log( "updateCell--------- rowIndex--->",tIndex );
      // let row = this.$refs['excelGrid'].table[tIndex];
      // console.log( "row ---------> ", row );

      let r = null;


      let param = {};
      const objectId = rc.keys[0];
      const fieldName = rc.name;
      console.log( "--- rc.newVal ---------> ",  rc.newVal );


      if( fieldName==='location') {
        param[fieldName] = this.setCoordinates(rc.newVal);
        this.$refs['excelGrid'].table[tIndex].location = param[fieldName];
        // this.tableKey++;
      } else {
        param[fieldName] = rc.newVal;
      }
      try {
        // const row = this.$refs['excelGrid'].currentRecord;
        console.log('updateCell----param --->', param);
        r = await apiCall("PUT", `/api/tank/${objectId}`, param);
        console.log(r);

        // console.log( 'table---row---data ---->', this.$refs['excelGrid'].table[tIndex] )
        if(r.code===200) await setTanks();

        await this.toastResult(r, `${TankColumnMap[fieldName]} 수정`);

      } catch (err) {
        await this.alertDanger(err.message, r.code, 'danger');
        console.log(err);
      }
    },

    setCoordinates(v){
      console.log('setCoordinates =====>', v);
      let coordinates = v.split(',').map(e=>{return Number(e)});
      // const coordinates = v.split(',').map(e=>{ return Number(e)});
      if(coordinates.length!==2) {
        return v;
      }else{
        let ret = { type:'Point', coordinates };
        console.debug( '======== setCoordinates --- returns ---------> ', ret );
        return ret
      }

    },

    async deleteRecord(recs) {
      // let r = null, msg = null, notice = null;
      try {

        let params = this.$refs['excelGrid'].getSelectedRecords();
        this.progCount = 0;
        this.totalCount = params.length;
        if (!params.length) return this.toastInfo('[선텍된 레코드 없음] 좌측의 번호를 클릭하여 레코드 선택');

        console.log("deleteRecord --- recs ---", recs.length);
        console.log("deleteRecord --- params ", params);
        console.log("deleteRecord length ----> ", params.length);

        const confirmMsg = `${params.length} 개의 데이터를 삭제 합니다. 삭제된 데이터는 복구할 수 없으며 시스템 장애가 발생할수 있습니다. 진행 하시겠습니까?`;

        if (!(await this.confirmModal(confirmMsg, '레코드 삭제'))) {
          return;
        }

        let failCnt = 0;
        for (let record of params) {
          console.log("deleteRecord for --------->", record);
          const tIndex = this.$refs['excelGrid'].rowIndex[record.$id];
          const r = await apiCall('DEL', `/api/tank/${record._id}`);
          console.log(r);
          if (r.code === 200) {
            this.progCount++;
            this.$refs["excelGrid"].deleteRecord(tIndex);
          }
        }

        await this.alertModal(`삭제: ${this.progCount}건, 실패: ${failCnt}`, '레코드 삭제결과', 'warning');
        this.$refs['excelGrid'].clearAllSelected();
        await this.getTankList();

      } catch (err) {

        console.log(err);
      }
    },


  }
}
</script>
